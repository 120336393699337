<template>
  <div>
    <form-component
      :navigation-group="navigationGroup"
      :product-subcategories="productSubcategories"
      @onSubmit="onSubmit" />
  </div>
</template>

<script>
import FormComponent from '@/components/navigation/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { updateOrCreate } from '@/http/navigation';
import { all } from '@/http/product/subcategory';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      navigationGroup: {
        name: '',
        slug: '',
        navigation_subgroups: [],
      },
      productSubcategories: [],
    };
  },

  async created() {
    await this.fetchProductSubcategories();
  },

  methods: {
    async fetchProductSubcategories() {
      try {
        const { data } = await all();
        this.productSubcategories = data.data;
      } catch (e) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych podkategorii.');
      }
    },
    async onSubmit(form) {
      try {
        await updateOrCreate(form);

        this.showSuccessNotification('Dane zapisane', 'Element nawigacji został dodany.');
        this.$router.push({ name: 'navigation-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania elementu nawigacji. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
